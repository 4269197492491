import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import { Link, Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import toast from 'react-hot-toast';
import sanitize from 'sanitize-filename';

import { client } from 'app/http';
import { routes } from '@routes';

import { JobsService, JobSubscriptionsService } from 'app/API';
import { useAPI } from '@hooks/useAPI';
import { useAuthContext } from '@providers/AuthProvider';
import { useModalContext } from '@providers/ModalProvider';
import { useWebsocketContext } from '@providers/WebsocketProvider';
import { Restricted } from '@providers/PermissionProvider/Restricted';
import { getAmericanTime } from '@helpers/getAmericanTimezone';
import { JOB_STATUSES_ENUM } from '@constants/enums/jobStatuses';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { getRepoZip } from '@queries/repositories/getRepositoryZip';

import { jobCanBeShared } from '@helpers/jobStatus/jobCanBeShared';
import { jobCanBeDownloaded } from '@helpers/jobStatus/jobCanBeDownloaded';
import { jobIsScheduled } from '@helpers/jobStatus/jobIsScheduled';
import { jobCanBeReassigned } from '@helpers/jobStatus/jobCanBeReassigned';
import { jobCanBeOpenedInASD } from '@helpers/jobStatus/jobCanBeOpenedInASD';
import { fakeDownloadClick, getLinkPrefix, handleOpenDesktop } from '@helpers/jobLinkHelpers';

import { Header } from '@components/Header';
import { AbsoluteSmallSpinner } from '@components/spinners/Spinner';
import { Pencil as PencilIcon } from '@components/icons/Pencil';
import { Done as DoneIcon } from '@components/icons/Done/svg';
import { IconButton } from '@components/form-elements/buttons/IconButton';
import { Input } from '@components/form-elements/Input';
import { Desktop as DesktopIcon } from '@components/icons/Desktop';
import { ASOne as ASOneIcon } from '@components/icons/ASOne';
import { CustomTable } from '@components/Table';
import { Share } from '@components/icons/ShareIcon';
import { FileIcon } from '@components/icons/File';
import { DownloadZip } from '@components/icons/DownloadZip';
import { AudioPlayer } from '@components/AudioPlayer';
import { Button } from '@components/form-elements/buttons/Button';
import { DownloadJob } from '@components/icons/DownloadJob';

import { TJob } from 'app/types/entities/TJob';
import { TJobAssigneeHistory } from 'app/types/entities/TJobAssigneeHistory';
import { TCheckoutEvent } from 'app/types/entities/TCheckoutEvent';
import { EPermission } from 'app/types/enums/EPermission';
import {
  JobStatusActionsButton,
  showClaimButton,
  showMarkAsCompleteButton,
  showMarkAsEditedButton,
  showMarkAsProofreadButton,
} from '@pages/User/RepositoryPage/components/JobsTable/JobStatusActionsButton';

import MetricsList from './components/MetricsList';
import { CommentForm } from './components/CommentForm';
import { CommentsList } from './components/CommentsList';

import EyeIcon from './icons/eye.svg';
import EyeClosedIcon from './icons/eye-closed.svg';
import { Popover } from '@components/Popover';
import { CheckBox } from '@components/form-elements/CheckBox';

const AssigneeTable = styled(CustomTable)`
  width: 100%;
  margin: -10px 0 0;

  thead tr {
    border: none;
  }

  thead td,
  thead th {
    padding: 0;
    height: 40px;
  }
`;

const CheckoutEventsTable = styled(CustomTable)`
  width: 100%;
  margin: -10px 0 0;

  thead tr {
    border: none;
  }

  thead td,
  thead th {
    padding: 0;
    height: 40px;
  }
`;

const CellContent = styled.div`
  margin: 0;
  padding: 10px 0;

  p {
    margin: 0;
  }
`;

const StyledLink = styled(RouterLink)`
  color: #00122d;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  text-decoration: underline;

  &:hover {
    color: #40608f;
  }
`;

const DownloadZipLink = styled.button`
  color: #858dbd;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  font-family: 'General Sans', sans-serif;
  display: flex;
  margin-top: 12px;
  padding: 5px;

  span {
    margin-left: 5px;
  }
`;

const Wrapper = styled.div`
  padding: 30px 30px 0;
  font-family: 'General Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  position: relative;
`;

const InfoBlockWrapper = styled.div`
  display: flex;
  gap: 25px 10px;
  flex-wrap: wrap;
`;

const InfoBlock = styled.div`
  width: 300px;
`;

const InfoBlockFullSize = styled(InfoBlock)`
  width: 100%;

  &.actions {
    button {
      max-width: 200px;
    }
  }
`;

const InfoBlockTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: #858dbd;
  padding: 0 0 10px;
`;

const InfoBlockContent = styled.div`
  color: #00122d;

  a {
    color: #00122d;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    text-decoration: underline;

    &:hover {
      color: #40608f;
    }
  }

  .pencil-button {
    cursor: pointer;

    &:hover {
      svg path {
        fill: #40608f;
      }
    }
  }
`;

const FlexBlockContent = styled(InfoBlockContent)`
  display: flex;
  gap: 10px;
  align-items: center;

  .pencil {
    cursor: pointer;

    :hover {
      path {
        fill: #858dbd;
      }
    }
  }
`;

const AssigneeBlockContent = styled(InfoBlockContent)`
  display: flex;
  gap: 10px;
  align-items: center;

  svg {
    cursor: pointer;

    :hover {
      path {
        fill: #858dbd;
      }
    }
  }

  .assignee {
    text-overflow: ellipsis;
    overflow: hidden;

    .assignName {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      margin: 0;
    }

    .assignEmail {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 100%;
      margin: 0;
    }
  }
`;

const JobRightRow = styled.div`
  display: flex;
  align-items: center;
`;

const TopRightSection = styled.div`
  display: flex;
  justify-content: end;
  gap: 15px;
  position: relative;
`;

const JobSection = styled.div`
  display: flex;
  align-items: start;
  padding: 20px 0;
`;

const Divider = styled.div`
  border-bottom: 1px solid #d5def2;
`;

const JobSectionLeft = styled.div`
  width: 170px;
  flex: 0 0 auto;
  padding-right: 30px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #858dbd;
`;

const JobSectionRight = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #00122d;
  word-break: break-word;
`;

const FullWidthSectionRight = styled(JobSectionRight)`
  width: 100%;
`;

const FlexTitle = styled.div`
  display: flex;
  gap: 10px;
  padding: 0 0 20px;
`;

const FlexNotes = styled.div`
  padding: 20px 0 0;
  display: flex;
  gap: 10px;
  align-items: center;

  button {
    &:hover {
      path {
        fill: #858dbd;
        color: #858dbd;
      }
    }
  }
`;

const FlexTitleText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100vw - 565px);
`;

const StyledIconButton = styled(IconButton)`
  width: 22px;
  align-self: center;

  svg {
    width: 100%;
    height: 100%;
  }

  path {
    fill: #d5def2;
  }

  :hover {
    path {
      fill: #858dbd;
      color: #858dbd;
    }
  }
`;
const FileIcons = styled.div`
  display: flex;
`;
const FileIconLink = styled(IconButton)`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  padding: 0 25px 0 0;
  color: #858dbd;
  align-self: center;

  svg {
    width: 17px;
    height: 20px;
    margin: 0 8px 0 0;
  }

  :hover {
    color: #858dbd;
  }
`;

const ShareIconButton = styled(IconButton)`
  gap: 10px;
  color: #858dbd;
  align-self: center;
  position: relative;

  svg {
    width: 18px;
    height: 18px;
  }

  path {
    fill: #858dbd;
  }

  :hover {
    color: #858dbd;

    path {
      fill: #858dbd;
      color: #858dbd;
    }
  }
  .popover-list {
    .input-wrapper {
      display: flex;
      padding: 5px 0;
    }
  }
`;
const StyledIconButtonDone = styled(IconButton)`
  width: 18px;
  align-self: center;

  svg {
    width: 100%;
    height: 100%;
  }

  path {
    fill: #d5def2;
  }

  :hover {
    path {
      fill: #858dbd;
      color: #858dbd;
    }
  }
`;

const StyledIconLink = styled.a`
  width: 200px;
  height: 30px;
  align-self: center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  flex: 0 0 auto;
  gap: 10px;
  color: #858dbd;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
`;

const StyledButtonIconLink = styled.a`
  width: auto;
  padding: 0 5px;
  height: 30px;
  align-self: center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  flex: 0 0 auto;
  gap: 10px;
  color: #858dbd;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;

  :hover {
    background: #cadcf8;

    path {
      stroke: #fff;
      color: #fff;
    }
  }
`;

const StyledDesktopButtonIconLink = styled.a`
  width: auto;
  padding: 0 5px;
  height: 30px;
  align-self: center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  flex: 0 0 auto;
  gap: 10px;
  color: #858dbd;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;

  :hover {
    background: #cadcf8;

    path {
      fill: #fff;
      color: #fff;
    }
  }
`;

const SmallWhiteInput = styled(Input)`
  input {
    background: #ffffff;
    padding: 0 10px;
    height: 30px;
    width: 350px;
  }
`;

const NoAccessWrapper = styled.div`
  padding: 30px;
  font-family: 'General Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
const NoAccessTitle = styled.h1`
  text-align: center;
  font-size: 22px;
  line-height: 30px;
  font-weight: 500;
  padding: 0 0 20px;
`;
const NoAccessContent = styled.h4`
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  max-width: 460px;
`;

const SummaryLink = styled.div`
  font-family: General Sans;
  font-size: 13px;
  font-weight: 500;
  line-height: 13px;
  text-align: center;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  height: 13px;
  gap: 0;
  color: #858dbd;
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;

  .tag {
    position: relative;
    padding: 4px 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #858dbd;
    background: #f1f5fb;
    border-radius: 5px;
    margin: 0 5px 5px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 150px;
  }

  button {
    svg {
      height: 22px;
    }

    &:hover {
      path {
        fill: #858dbd;
        color: #858dbd;
      }
    }
  }
`;

export const JobDetailsPage = () => {
  const { call } = useAPI();
  const params = useParams() as { id: string; orgSlug: string; wsSlug: string };
  const jobId = params?.id ?? '';
  const wsSlug = params?.wsSlug ?? '';
  const { me, workspace, organization } = useAuthContext();
  const { openModal, closeModal } = useModalContext();
  const { jobId: jobChangedId, offline: websocketsOffline } = useWebsocketContext();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement | EventTarget>(null);
  const [job, setJob] = useState<TJob>();
  const [editTitle, setEditTitle] = useState(false);
  const [jobName, setJobName] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const meWatcher = job?.watchers?.find((w) => w.userId === me.id);
  const [watchStatus, setWatchStatus] = useState(meWatcher?.status || false);
  const [watchAssignee, setWatchAssignee] = useState(meWatcher?.assignee || false);
  const [watchComments, setWatchComments] = useState(meWatcher?.comments || false);

  const [outputUrl, setOutputUrl] = useState('');
  const [summaryUrl, setSummaryUrl] = useState('');

  const showDownloads =
    (me.id === job?.assigneeId || organization?.permissions?.viewJobs === 'all') &&
    job?.results?.length &&
    job.status &&
    jobCanBeDownloaded(job.status);
  const showSourceFiles = job?.sourceFiles?.length && job.status && !job.asOneLocked && jobCanBeDownloaded(job.status);
  const canEditJob = organization?.permissions?.editJobs;
  const showMaterials = !!job?.notes || canEditJob || showDownloads || !!job?.repository?.slug;
  const showSchedule = !!job?.meetingType;
  const showVersions = !!job?.checkoutEvents?.length;
  const canBeOpenedInASD = job?.status && jobCanBeOpenedInASD(job?.status);
  const showActions =
    showClaimButton(job, organization) ||
    showMarkAsCompleteButton(job, organization) ||
    showMarkAsEditedButton(job, organization) ||
    showMarkAsProofreadButton(job, organization);
  const showLinkToUser = !!organization?.permissions?.manageMembers;

  const canReassignJob = job?.status && organization?.permissions?.reassignJobs && jobCanBeReassigned(job.status);

  useEffect(() => {
    if (!workspace || !workspace.id) {
      return;
    }
    if (job && jobChangedId !== jobId) {
      return;
    }
    load();
  }, [jobId, workspace?.id, jobChangedId]);

  useEffect(() => {
    const getSummaryLinks = async () => {
      const onSuccess = (response: any) => {
        const { downloadOutputUrl, downloadSummaryUrl } = response;
        if (downloadOutputUrl && downloadSummaryUrl) {
          setOutputUrl(downloadOutputUrl);
          setSummaryUrl(downloadSummaryUrl);
        }
      };

      await call(JobsService.getJobPartialUnlockedFiles({ id: jobId }), {
        onSuccess,
      });
    };

    getSummaryLinks();
  }, []);

  useEffect(() => {
    (async () => {
      if (isLoading || !jobId || !workspace || !workspace.id) {
        return;
      }
      if (!watchStatus && !watchAssignee && !watchComments) {
        setAnchorEl(null);
        await call(JobSubscriptionsService.delete({ jobId }));
        load();
        return;
      }
      await call(
        JobSubscriptionsService.upsert({
          jobId,
          requestBody: { status: watchStatus, assignee: watchAssignee, comments: watchComments },
        }),
      );
      load();
    })();
  }, [jobId, watchStatus, watchAssignee, watchComments]);

  const getWorkspaceBySlug = () => {
    return (me?.workspaces || []).find((ws) => ws.slug === wsSlug && ws.organizationId === organization?.id);
  };

  const renderContactAdministratorContent = () => {
    return (
      <>
        <Helmet>
          <title>Job Details - AutoScript</title>
        </Helmet>
        <Header title="Job Details" />

        <NoAccessWrapper>
          <NoAccessTitle>Job not found</NoAccessTitle>
          <NoAccessContent>
            If you believe you should have access to this job please contact your Organization administrator
          </NoAccessContent>
        </NoAccessWrapper>
      </>
    );
  };

  const renderJobIsDeletedContent = () => {
    return (
      <>
        <Helmet>
          <title>Job Details - AutoScript</title>
        </Helmet>
        <Header title="Job Details" />

        <NoAccessWrapper>
          <NoAccessTitle>Job is deleted</NoAccessTitle>
          <NoAccessContent>Something went wrong</NoAccessContent>
        </NoAccessWrapper>
      </>
    );
  };

  const renderJobNotFound = () => {
    return (
      <>
        <Helmet>
          <title>Job Details - AutoScript</title>
        </Helmet>
        <Header title="Job Details" />

        <NoAccessWrapper>
          <NoAccessTitle>Job not found</NoAccessTitle>
          <NoAccessContent>Please contact your Organization Administrator</NoAccessContent>
        </NoAccessWrapper>
      </>
    );
  };

  const load = async () => {
    if (wsSlug !== workspace?.slug && !getWorkspaceBySlug()) {
      console.error('Cannot load this job because its in a different workspace');
      return;
    }
    setIsLoading(true);
    call(JobsService.getJobById({ id: jobId, wsid: workspace?.id ?? '' }))
      .then((job: TJob) => {
        setJob(job);
        setJobName(job.name);
        setIsLoading(false);
        setEditTitle(false);
        const meWatcher = job?.watchers?.find((w) => w.userId === me.id);
        setWatchStatus(meWatcher?.status || false);
        setWatchAssignee(meWatcher?.assignee || false);
        setWatchComments(meWatcher?.comments || false);
        if (job.asOneLocked) {
          navigate(routes.jobsList.make(organization?.slug ?? '', wsSlug));
        }
      })
      .catch(() => {
        setJob(undefined);
        setIsLoading(false);
        setEditTitle(false);
      });
  };

  if (me.workspaces.length && wsSlug !== workspace?.slug && !getWorkspaceBySlug()) {
    return renderContactAdministratorContent();
  }

  if (job?.removed) {
    return renderJobIsDeletedContent();
  }

  if (!isLoading && !job) {
    return renderJobNotFound();
  }

  const saveJobTitle = async () => {
    await call(JobsService.updateTitle({ id: jobId, wsid: workspace?.id ?? '', requestBody: { name: jobName } }));
    load();
  };

  const renderOpenInASOne = () => {
    const getASOneLinkPrefix = () => {
      return `${window.location.origin}/as-one/job/`;
    };
    const showButton = job?.status && !job.asOneLocked && jobIsScheduled(job.status);
    if (!showButton) {
      return null;
    }
    return (
      <StyledIconLink target="_blank" rel="noreferrer" href={`${getASOneLinkPrefix()}${job?.meetingId}`}>
        <ASOneIcon />
        Open with AutoScriptOne
      </StyledIconLink>
    );
  };

  const renderShareLink = () => {
    const handleCopyButtonClick = (link: string) => {
      navigator.clipboard.writeText(link);
    };

    const showShareButton = job?.status ? jobCanBeShared(job?.status) : false;
    if (!showShareButton) {
      return null;
    }
    return (
      <ShareIconButton
        title="Copy link to clipboard"
        onClick={() => {
          toast.success('The link has been copied to the clipboard', {
            duration: 2000,
          });
          handleCopyButtonClick(`${getLinkPrefix()}${job?.id}`);
        }}
      >
        <Share />
        Share job link
      </ShareIconButton>
    );
  };

  const renderWatchButton = () => {
    const showWatchButton = !!job;
    if (!showWatchButton) {
      return null;
    }
    if (meWatcher) {
      return (
        <ShareIconButton
          title="Unwatch this job"
          onClick={async (e) => {
            setAnchorEl(e.target);
            // await call(JobSubscriptionsService.delete({ jobId }));
            // load();
          }}
        >
          <EyeClosedIcon style={{ marginRight: '-5px', height: '28px', width: '28px' }} />
          Unwatch
        </ShareIconButton>
      );
    }
    return (
      <ShareIconButton
        title="Watch this job"
        onClick={async (e) => {
          // await call(
          //   JobSubscriptionsService.upsert({
          //     jobId,
          //     requestBody: { status: true, assignee: true, comments: true },
          //   }),
          // );
          // setWatchStatus(true);
          // setWatchAssignee(true);
          // setWatchComments(true);
          // load();
          setAnchorEl(e.target);
        }}
      >
        <EyeIcon style={{ marginRight: '-5px', height: '28px', width: '28px' }} />
        Watch
      </ShareIconButton>
    );
  };

  const renderWatchSettings = () => {
    return (
      <Popover
        open={anchorEl !== null}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorEl={anchorEl as HTMLElement}
        styles="
            padding: 10px 15px;
            margin-top: 0px;
            margin-right: 0px;
            overflow: hidden;
            right: 0px;
            top: 30px;
            box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
            border-radius: 5px;"
      >
        <div className="popover-list">
          <div className="input-wrapper checkbox-wrapper">
            <CheckBox checked={watchStatus} onChange={(v) => setWatchStatus(v)} />
            <label>Status updates</label>
          </div>
          <Divider className="divider" />
          <div className="input-wrapper checkbox-wrapper">
            <CheckBox checked={watchAssignee} onChange={(v) => setWatchAssignee(v)} />
            <label>Assignee updates</label>
          </div>
          <Divider className="divider" />
          <div className="input-wrapper checkbox-wrapper">
            <CheckBox checked={watchComments} onChange={(v) => setWatchComments(v)} />
            <label>Comments</label>
          </div>
        </div>
      </Popover>
    );
  };

  const editAssignee = (job: TJob) => {
    openModal(ModalNamesEnum.AssignJobModal, {
      job,
      onSuccess: () => {
        closeModal();
        load();
      },
    });
  };

  const editTags = () => {
    openModal(ModalNamesEnum.EditJobTagsModal, {
      job,
      onSuccess: () => {
        closeModal();
        load();
      },
    });
  };

  const editNotes = () => {
    openModal(ModalNamesEnum.EditJobNotesModal, {
      job,
      onSuccess: () => {
        closeModal();
        load();
      },
    });
  };

  const editCase = () => {
    openModal(ModalNamesEnum.EditJobCaseModal, {
      job,
      onSuccess: () => {
        closeModal();
        load();
      },
    });
  };

  const complicatedTitleEdit = () => {
    if (!organization?.permissions?.editJobs) {
      return job?.name;
    }

    if (editTitle) {
      return (
        <FlexTitle>
          <SmallWhiteInput
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setJobName(e.target.value)}
            name="name"
            label="Title"
            value={jobName}
            required={true}
          />
          <StyledIconButtonDone title="Save" onClick={() => saveJobTitle()}>
            <DoneIcon />
          </StyledIconButtonDone>
        </FlexTitle>
      );
    }

    return (
      <FlexTitle>
        <FlexTitleText>{job?.name}</FlexTitleText>
        <StyledIconButton title="Edit" onClick={() => setEditTitle(true)}>
          <PencilIcon />
        </StyledIconButton>
      </FlexTitle>
    );
  };

  const assigneeTableProps = [
    {
      headComponent: () => <td style={{ width: '200px' }}>Change date</td>,
      render: (data: TJobAssigneeHistory) => <CellContent>{getAmericanTime(data.createdAt)}</CellContent>,
    },
    {
      headComponent: () => <td>User assigned</td>,
      render: (data: TJobAssigneeHistory) => (
        <CellContent>
          {data.user?.name && `${data.user?.name} ${data.user?.lastname}`}
          {!data.user?.name && 'Unassigned'}
        </CellContent>
      ),
    },
  ];

  const downloadFileVersion = async (jobId: string, versionId: string, jobName = 'job') => {
    const fileName = sanitize(jobName)
      .toLowerCase()
      .replace(/[^a-zA-Z0-9()_.]/gi, '-');
    await client
      .get(`${window.location.origin}/api/media/${jobId}/versions/${versionId}`, {
        responseType: 'blob',
      })
      .then(fakeDownloadClick(`${fileName}.docx`));
  };

  const checkoutEventTableProps = [
    {
      headComponent: () => <td>Assignee</td>,
      render: (data: TCheckoutEvent) => (
        <CellContent>
          {showLinkToUser ? (
            <Link to={routes.userDetails.make(organization?.slug ?? '', data.user?.id ?? '')} className="underline">
              {data.user?.name && `${data.user?.name} ${data.user?.lastname}`}
            </Link>
          ) : (
            data.user?.name && `${data.user?.name} ${data.user?.lastname}`
          )}
        </CellContent>
      ),
    },
    {
      headComponent: () => <td>Checkout</td>,
      render: (data: TCheckoutEvent) => <CellContent>{getAmericanTime(data.createdAt)}</CellContent>,
    },
    {
      headComponent: () => <td>Last update</td>,
      render: (data: TCheckoutEvent) => (
        <CellContent>{data.finalVersion ? getAmericanTime(data.finalVersion.createdAt) : '-'}</CellContent>
      ),
    },
    {
      headComponent: () => <td>Status</td>,
      render: (data: TCheckoutEvent) => (
        <CellContent>{JOB_STATUSES_ENUM[data.jobStatus as keyof typeof JOB_STATUSES_ENUM]}</CellContent>
      ),
    },
    {
      headComponent: () => <td>Originating version</td>,
      render: (data: TCheckoutEvent) => (
        <CellContent>
          <a
            onClick={() =>
              handleOpenDesktop(job?.id || '', data.initialVersion?.predecessorId ? data.predecessor?.id : undefined)
            }
            className="download-version-link underline align-left align-items-left text-sm cursor-pointer"
          >
            {data.initialVersion?.predecessorId
              ? `${data.predecessor.user.name} ${data.predecessor.user.lastname} - ${getAmericanTime(
                  data.predecessor.createdAt,
                )}`
              : 'Speech-to-Text version'}
          </a>
        </CellContent>
      ),
    },
    {
      headComponent: () => <td></td>,
      render: (data: TCheckoutEvent) => (
        <CellContent className="flex flex-row">
          <StyledButtonIconLink
            onClick={() => downloadFileVersion(job?.id || '', data.finalVersionId, job?.name)}
            className="download-version-link"
          >
            <DownloadJob />
          </StyledButtonIconLink>
          <StyledDesktopButtonIconLink
            target="_blank"
            rel="noreferrer"
            onClick={() => handleOpenDesktop(job?.id || '', data.finalVersionId)}
          >
            <DesktopIcon />
          </StyledDesktopButtonIconLink>
        </CellContent>
      ),
    },
  ];

  const downloadFile = async (jobId: string, fileName: string) => {
    await client
      .get(`${window.location.origin}/api/media/${jobId}/transcript/${fileName}`, {
        responseType: 'blob',
      })
      .then(fakeDownloadClick(fileName));
  };

  const downloadSourceFile = async (jobId: string, fileName: string) => {
    await client
      .get(`${window.location.origin}/api/media/${jobId}/${fileName}`, {
        responseType: 'blob',
      })
      .then(fakeDownloadClick(fileName));
  };

  const downloadReporterNotes = async (jobId: string, fileName: string) => {
    await client
      .get(`${window.location.origin}/api/media/${jobId}/reporter-notes/${fileName}`, {
        responseType: 'blob',
      })
      .then(fakeDownloadClick(fileName));
  };

  const downloadAttachment = async (jobId: string, fileName: string) => {
    await client
      .get(`${window.location.origin}/api/media/${jobId}/attachments/${fileName}`, {
        responseType: 'blob',
      })
      .then(fakeDownloadClick(fileName));
  };

  const getZipFileUrl = async () => {
    setIsLoading(true);
    getRepoZip(jobId, workspace?.id ?? '').then((response) => {
      setIsLoading(false);
      if (response) {
        window.open(response, '_blank');
      }
    });
  };

  type meetingType = 'RECORDING_SESSION_REMOTE' | 'RECORDING_SESSION_HYBRID' | 'RECORDING_SESSION_IN_PERSON';
  const meetingTypeNames = {
    RECORDING_SESSION_REMOTE: 'Remote',
    RECORDING_SESSION_HYBRID: 'Hybrid',
    RECORDING_SESSION_IN_PERSON: 'In Person',
  };

  const editDeadline = () => {
    openModal(ModalNamesEnum.EditDeadline, {
      onSuccess: () => {
        load();
        closeModal();
      },
      job,
    });
  };

  const showAttachments = !!showDownloads && job.attachments?.length > 0;
  const showReporterNotes = !!showDownloads && !!job?.reporterNotes?.length;

  return (
    <>
      <Helmet>
        <title>Job Details - AutoScript</title>
      </Helmet>
      <Header title="Job Details" />

      {job ? (
        <Wrapper>
          {isLoading ? <AbsoluteSmallSpinner overlay={true} /> : null}
          <TopRightSection>
            {renderOpenInASOne()}
            {renderWatchButton()}
            {renderWatchSettings()}
            {renderShareLink()}
          </TopRightSection>
          <JobSection>
            <JobSectionLeft>General</JobSectionLeft>
            <JobSectionRight>
              <JobRightRow>{complicatedTitleEdit()}</JobRightRow>
              <InfoBlockWrapper>
                <InfoBlock>
                  <InfoBlockTitle>Job ID</InfoBlockTitle>
                  <InfoBlockContent>{job.id}</InfoBlockContent>
                </InfoBlock>

                <InfoBlock>
                  <InfoBlockTitle>Status</InfoBlockTitle>
                  <InfoBlockContent id="job_details_status">{JOB_STATUSES_ENUM[job.status]}</InfoBlockContent>
                </InfoBlock>

                {job.bucket ? (
                  <InfoBlock>
                    <InfoBlockTitle>S3 Bucket</InfoBlockTitle>
                    <InfoBlockContent>
                      <a
                        href={`https://s3.console.aws.amazon.com/s3/buckets/${job.bucket}?region=${job.region}&prefix=jobs/${job.id}/`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        Link
                      </a>
                    </InfoBlockContent>
                  </InfoBlock>
                ) : null}

                <InfoBlock>
                  <InfoBlockTitle>Date created</InfoBlockTitle>
                  <InfoBlockContent>{getAmericanTime(job.createdAt)}</InfoBlockContent>
                </InfoBlock>

                <InfoBlock>
                  <InfoBlockTitle>Deadline</InfoBlockTitle>
                  <FlexBlockContent>
                    {job.deadline ? getAmericanTime(job.deadline, false) : 'Not set'}
                    {organization?.permissions?.submitJobs && (
                      <PencilIcon className="pencil" onClick={() => editDeadline()} />
                    )}
                  </FlexBlockContent>
                </InfoBlock>

                <InfoBlock>
                  <InfoBlockTitle>Assignee</InfoBlockTitle>
                  <AssigneeBlockContent>
                    {job?.assignee ? (
                      <div className="assignee">
                        <StyledLink to={routes.userDetails.make(organization?.slug ?? '', job?.assignee?.id)}>
                          <p className="assignName">
                            {job?.assignee?.name} {job?.assignee?.lastname}
                          </p>
                          <p className="assignEmail">{job?.assignee?.email || ''}</p>
                        </StyledLink>
                      </div>
                    ) : null}
                    {!job?.assignee ? (
                      <div className="assignee">
                        <p className="assignName">Unassigned</p>
                      </div>
                    ) : null}
                    {canReassignJob ? <PencilIcon onClick={() => editAssignee(job)} /> : null}
                  </AssigneeBlockContent>
                </InfoBlock>

                {!!job.tags?.length || canEditJob ? (
                  <InfoBlock>
                    <InfoBlockTitle>Tags</InfoBlockTitle>
                    <InfoBlockContent>
                      <Tags>
                        {(job.tags || []).map((tag: string, index: number) => (
                          <div className="tag" key={index}>
                            {tag}
                          </div>
                        ))}
                        {canEditJob ? (
                          <button onClick={() => editTags()}>
                            <PencilIcon />
                          </button>
                        ) : null}
                      </Tags>
                    </InfoBlockContent>
                  </InfoBlock>
                ) : null}

                <InfoBlock>
                  <InfoBlockTitle>Cloud layout</InfoBlockTitle>
                  <FlexBlockContent>{job.layoutName || 'None'}</FlexBlockContent>
                </InfoBlock>
              </InfoBlockWrapper>
            </JobSectionRight>
          </JobSection>

          <Divider />

          {showActions ? (
            <>
              <JobSection>
                <JobSectionLeft>Actions</JobSectionLeft>
                <FullWidthSectionRight>
                  <InfoBlockFullSize className="actions">
                    <JobStatusActionsButton job={job} reload={load} />
                  </InfoBlockFullSize>
                </FullWidthSectionRight>
              </JobSection>
              <Divider />
            </>
          ) : null}

          {showVersions ? (
            <>
              <JobSection>
                <JobSectionLeft>Versions</JobSectionLeft>
                <FullWidthSectionRight>
                  <InfoBlockFullSize>
                    <CheckoutEventsTable data={job.checkoutEvents || []} head={checkoutEventTableProps} />
                  </InfoBlockFullSize>
                </FullWidthSectionRight>
              </JobSection>
              <Divider />
            </>
          ) : canBeOpenedInASD ? (
            <>
              <JobSection>
                <JobSectionLeft>Versions</JobSectionLeft>
                <FullWidthSectionRight>
                  <InfoBlockFullSize>
                    <Button
                      style={{ maxWidth: '400px', margin: '0 auto', display: 'block' }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleOpenDesktop(job?.id || '');
                      }}
                    >
                      Start editing in AutoScript
                    </Button>
                  </InfoBlockFullSize>
                </FullWidthSectionRight>
              </JobSection>
              <Divider />
            </>
          ) : null}

          {!!(job.checkoutEvents || []).length && <MetricsList job={job} />}

          {showMaterials ? (
            <>
              <JobSection>
                <JobSectionLeft>Materials</JobSectionLeft>
                <JobSectionRight>
                  <InfoBlockFullSize className="pb-4">
                    <InfoBlockTitle>Case</InfoBlockTitle>
                    <InfoBlockContent className="align-middle flex gap-2">
                      {job.repository?.slug ? (
                        <>
                          <Restricted to={EPermission.viewRepositories}>
                            <StyledLink
                              to={{
                                pathname: routes.repositoryDetails.make(params.orgSlug, job?.repository?.slug),
                              }}
                              state={{
                                from: routes.jobDetails.make(params.orgSlug, params.wsSlug, params.id),
                              }}
                            >
                              <p className="repository-name">{job?.repository?.name}</p>
                            </StyledLink>
                          </Restricted>
                          {job?.repository?.zipUrl ? (
                            <DownloadZipLink onClick={getZipFileUrl}>
                              <DownloadZip /> <span>Download ZIP</span>
                            </DownloadZipLink>
                          ) : null}
                        </>
                      ) : (
                        'Not set'
                      )}
                      {canEditJob ? (
                        <button className="pencil-button" onClick={() => editCase()}>
                          <PencilIcon />
                        </button>
                      ) : null}
                    </InfoBlockContent>
                  </InfoBlockFullSize>

                  {job.status !== 'JOB_STATUS_PROCESSING' && (
                    <InfoBlockFullSize className="pb-4">
                      <InfoBlockTitle>Reporter Summary</InfoBlockTitle>
                      <InfoBlockContent className="align-middle flex gap-2">
                        <a href={summaryUrl} target="_blank" rel="noreferrer" className="flex items-center">
                          <FileIcon className="mr-2" />
                          <SummaryLink>Court_Reporting_Summary.PDF</SummaryLink>
                        </a>

                        <a href={outputUrl} target="_blank" rel="noreferrer" className="flex ml-2 items-center">
                          <FileIcon className="mr-2" />
                          <SummaryLink>Court_Reporting_Audio.MP3</SummaryLink>
                        </a>
                      </InfoBlockContent>
                    </InfoBlockFullSize>
                  )}

                  {showDownloads ? (
                    <InfoBlockFullSize className="pb-4">
                      <InfoBlockTitle>Speech-to-Text Transcript</InfoBlockTitle>
                      <InfoBlockContent>
                        <FileIcons>
                          {job.results?.map((file) => (
                            <FileIconLink
                              data-testid={`jobDownloadItem${job.name}`}
                              key={file}
                              onClick={() => downloadFile(job.id, file)}
                              className="downloadLink"
                            >
                              <FileIcon />
                              {file}
                            </FileIconLink>
                          ))}
                        </FileIcons>
                      </InfoBlockContent>
                    </InfoBlockFullSize>
                  ) : null}

                  {showAttachments ? (
                    <InfoBlockFullSize className="pb-4">
                      <InfoBlockTitle>Attachments</InfoBlockTitle>
                      <InfoBlockContent>
                        <FileIcons>
                          {job.attachments?.map((file) => (
                            <FileIconLink
                              data-testid={`jobDownloadItem${job.name}`}
                              key={file}
                              onClick={() => downloadAttachment(job.id, file)}
                              className="downloadLink"
                            >
                              <FileIcon />
                              {file}
                            </FileIconLink>
                          ))}
                        </FileIcons>
                      </InfoBlockContent>
                    </InfoBlockFullSize>
                  ) : null}

                  {showSourceFiles ? (
                    <InfoBlockFullSize className="pt-3">
                      <InfoBlockTitle>Source Files</InfoBlockTitle>
                      <InfoBlockContent>
                        <FileIcons>
                          {job.sourceFiles?.map((file) => (
                            <FileIconLink
                              data-testid={`jobDownloadItem${job.name}`}
                              key={file}
                              onClick={() => downloadSourceFile(job.id, file)}
                              className="downloadLink"
                            >
                              <FileIcon />
                              {file}
                            </FileIconLink>
                          ))}
                        </FileIcons>
                      </InfoBlockContent>
                    </InfoBlockFullSize>
                  ) : null}

                  {showReporterNotes ? (
                    <InfoBlockFullSize className="pt-4">
                      <InfoBlockTitle>Reporter notes</InfoBlockTitle>
                      <InfoBlockContent>
                        <FileIcons>
                          {job.reporterNotes?.map((file) => (
                            <FileIconLink
                              data-testid={`jobDownloadItem${job.name}`}
                              key={file}
                              onClick={() => downloadReporterNotes(job.id, file)}
                              className="downloadLink"
                            >
                              <FileIcon />
                              {file}
                            </FileIconLink>
                          ))}
                        </FileIcons>
                      </InfoBlockContent>
                    </InfoBlockFullSize>
                  ) : null}

                  {job.processedAudioFilename ? (
                    <InfoBlockFullSize>
                      <InfoBlockTitle>
                        <FlexNotes>Audio</FlexNotes>
                      </InfoBlockTitle>
                      <InfoBlockContent>
                        <AudioPlayer
                          src={`${window.location.origin}/api/media/${jobId}/${job.processedAudioFilename}`}
                        />
                      </InfoBlockContent>
                    </InfoBlockFullSize>
                  ) : null}

                  {!!job.notes || canEditJob ? (
                    <InfoBlockFullSize>
                      <InfoBlockTitle>
                        <FlexNotes>
                          Notes
                          {canEditJob ? (
                            <button onClick={() => editNotes()}>
                              <PencilIcon />
                            </button>
                          ) : null}
                        </FlexNotes>
                      </InfoBlockTitle>
                      <InfoBlockContent>{job.notes?.length ? job.notes : 'Empty'}</InfoBlockContent>
                    </InfoBlockFullSize>
                  ) : null}
                </JobSectionRight>
              </JobSection>
              <Divider />
            </>
          ) : null}

          <JobSection>
            <JobSectionLeft>Comments</JobSectionLeft>
            <FullWidthSectionRight>
              <CommentForm
                workspaceId={workspace?.id ?? ''}
                jobId={jobId}
                setIsLoading={setIsLoading}
                onSuccess={() => {
                  if (websocketsOffline) {
                    load();
                  }
                }}
              />
              <CommentsList comments={job.comments} />
            </FullWidthSectionRight>
          </JobSection>
          <Divider />

          {showSchedule ? (
            <>
              <JobSection>
                <JobSectionLeft>Schedule</JobSectionLeft>
                <JobSectionRight>
                  <InfoBlockWrapper>
                    <InfoBlock>
                      <InfoBlockTitle>Date and time</InfoBlockTitle>
                      <InfoBlockContent>
                        <>
                          {getAmericanTime(job.meetingDate ?? '', false)} {job.meetingTime}
                        </>
                      </InfoBlockContent>
                    </InfoBlock>

                    {job.meetingAssigneeId ? (
                      <InfoBlock>
                        <InfoBlockTitle>Court reporter assignee</InfoBlockTitle>
                        <InfoBlockContent>
                          <div className="assignee">
                            <StyledLink
                              to={routes.userDetails.make(organization?.slug ?? '', job?.meetingAssignee?.id ?? '')}
                            >
                              <p className="assignName">
                                {job?.meetingAssignee?.name} {job?.meetingAssignee?.lastname}
                              </p>
                              <p className="assignEmail">{job?.meetingAssignee?.email || ''}</p>
                            </StyledLink>
                          </div>
                        </InfoBlockContent>
                      </InfoBlock>
                    ) : null}

                    <InfoBlock>
                      <InfoBlockTitle>Meeting type</InfoBlockTitle>
                      <InfoBlockContent>
                        {meetingTypeNames[(job.meetingType as meetingType) ?? 'RECORDING_SESSION_REMOTE']}
                      </InfoBlockContent>
                    </InfoBlock>

                    {job.meetingType !== 'RECORDING_SESSION_REMOTE' ? (
                      <InfoBlock>
                        <InfoBlockTitle>Address</InfoBlockTitle>
                        <InfoBlockContent>
                          {job.meetingAddress?.length ? job.meetingAddress : 'Not set'}
                        </InfoBlockContent>
                      </InfoBlock>
                    ) : null}

                    {job.meetingType !== 'RECORDING_SESSION_IN_PERSON' && job.meetingLink?.length ? (
                      <InfoBlock>
                        <InfoBlockTitle>Meeting link</InfoBlockTitle>
                        <InfoBlockContent>
                          <a target="_blank" href={job.meetingLink ?? ''} rel="noreferrer">
                            Meeting link to start session
                          </a>
                        </InfoBlockContent>
                      </InfoBlock>
                    ) : null}
                  </InfoBlockWrapper>
                </JobSectionRight>
              </JobSection>
              <Divider />
            </>
          ) : null}

          {job.assigneeHistory?.length ? (
            <>
              <JobSection>
                <JobSectionLeft>Workflow</JobSectionLeft>
                <FullWidthSectionRight>
                  <InfoBlockFullSize>
                    <AssigneeTable data={job.assigneeHistory} head={assigneeTableProps} />
                  </InfoBlockFullSize>
                </FullWidthSectionRight>
              </JobSection>
              <Divider />
            </>
          ) : null}
        </Wrapper>
      ) : null}
    </>
  );
};
